<template>
  <el-dialog title="上级企业单位" :visible.sync="paraentEnterVisible" :close-on-click-modal="false"  append-to-body :before-close="handleClose">
    <el-form ref="form" inline :model="queryParams" class="demo-form-inline">
      <el-form-item label="统一社会信用代码"  >
        <el-input clearable class="w230px" placeholder="请输入" v-model="queryParams.socialCode"></el-input>
      </el-form-item>
      <el-form-item label="企业单位名称" label-width="120px">
        <el-input clearable class="w230px" placeholder="请输入" v-model="queryParams.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="cruds"
      border
      :query="queryParams"
      :data="parentEnterData"
      highlight-current-row
      @row-click="cardRowClick"
    >
      <template slot="empty">
        <IsEmpty />
      </template>
      <el-table-column type="index" label="序号" width="50" align="center" :index="count"/>
      <el-table-column label="选择" width="80"  align="center">
        <template slot-scope="scope">
          <el-radio-group  @change="cardRadionChange(scope.row)" v-model="checkedId">
            <el-radio :label="scope.row.id">{{""}}</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="企业单位名称" align="center" prop="name" />
      <el-table-column label="统一社会信用代码" align="center" prop="socialCode" />
    </el-table>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getSuperiorEnterpriseList
  } from "@/api/unitManage"
  export default {
    name: "paraentEnterDilog",
    props: {
      paraentEnterVisible: {
        type: Boolean,
        default: false
      },
      mytype: {
        default: ''
      }
    },
    data() {
      return {
        //单选是否选中
        checkedId: '', //选中ID
        checkedName: '', //选中名字
        parentEnterData: [],
        total: 0,
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          socialCode: '',
          name: ''
        }
      }
    },
    watch: {
      mytype: {
        handler(val) {
          if(!val || val === 2) {
            this.checkedId = '', //选中ID
              this.checkedName = '' //选中名字
          }
        }
      }
    },
    mounted() {
      this.getSuperiorEnterpriseListData()
    },
    methods: {
      //确定
      confirm() {
        this.$emit('update:paraentEnterVisible', false)
        this.$emit('checkedItem', { parentEnterpriseId: this.checkedId, parentEnterpriseName: this.checkedName })
      },
      //分页序号
      count(index) {
        return (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
      },
      handleClose() {
        this.$emit('update:paraentEnterVisible', false)
      },
      /*行的单击事件*/
      cardRowClick(row){
       this.checkedId = row.id
        this.checkedName = row.name
      },
      /*单选按钮的改变事件*/
      cardRadionChange(row) {
        this.checkedId = row.id
        this.checkedName = row.name
      },
      onSubmit() {
        this.queryParams.pageNum = 1
        this.getSuperiorEnterpriseListData()
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        this.getSuperiorEnterpriseListData()
      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum
        this.getSuperiorEnterpriseListData()
      },
      //  查询上级企业单位
      async getSuperiorEnterpriseListData() {
        const res = await getSuperiorEnterpriseList(this.queryParams)
        if(res.state === 0) {
          this.parentEnterData = res.data.rows
          this.total = res.data.total
        }
      }
    }
  }
</script>

<style scoped>
  .w230px {
    width: 230px;
  }
</style>
